import React, { FC } from 'react'
import { joinClasses, makeClasses } from '@utils/styles'
import { Colors } from '@utils/css-variables'
import Breakpoints from '@utils/breakpoints'

type Props = {
	currentStep: number
	currentSubStep: number
	steps: { title: string; subSteps?: string[] }[]
	onSetSubStep: (subStep: number) => void
}

type ClassType = {
	root: string
	stepContainer: string
	step: string
	subStep: string
	circle: string
	text: string
	icon: string
	line: string
}

const classes: ClassType = makeClasses({
	root: {
		marginTop: '30px',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'flex-start',
		width: '100%',
		position: 'relative',
		transition: 'width .6s ease',
		[Breakpoints.maxWidth('md')]: {
			flexDirection: 'row',
			justifyContent: 'center',
			alignItems: 'flex-start',
			overflowX: 'auto',
			padding: '0',
			marginTop: '0px'
		}
	},
	stepContainer: {
		display: 'flex',
		flexDirection: 'column',
		position: 'relative',
		fontSize: '12px',
		height: 'auto',
		lineHeight: '1em',
		whiteSpace: 'normal',
		'&:not(:last-child)::after': {
			content: '""',
			position: 'absolute',
			left: '16px',
			top: '30px',
			width: '2px',
			height: '100%',
			backgroundColor: '#cccccc',
			[Breakpoints.maxWidth('md')]: {
				left: '26px',
				top: '12px',
				width: '20px',
				height: '2px'
			}
		},
		[Breakpoints.maxWidth('md')]: {
			flexDirection: 'column',
			alignItems: 'center',
			marginRight: '10px',
			'&:last-child': {
				marginRight: 0
			}
		}
	},
	step: {
		position: 'relative',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'flex-start',
		width: '100%',
		color: '#666666',
		margin: '8px 0',
		'&.step-complete': {
			color: Colors.secondary,
			'.circle': {
				background: Colors.secondary,
				borderColor: Colors.secondary
			},
			span: {
				color: Colors.white
			}
		},
		'&.step-current': {
			color: Colors.secondary,
			'.circle': {
				borderColor: Colors.secondary
			}
		},
		[Breakpoints.maxWidth('md')]: {
			flexDirection: 'column',
			alignItems: 'center',
			margin: '0',
		}
	},
	subStep: {
		paddingLeft: '40px',
		color: '#999999',
		margin: '5px 0',
		fontWeight: 500,
		position: 'relative',
		fontSize: '11px',
		'&.subStep-complete': {
			cursor: 'pointer',
			color: Colors.darkGrey,
			'&:hover': {
				textDecoration: 'underline'
			},
			'&::before': { backgroundColor: Colors.darkGrey }
		},
		'&.subStep-current': {
			color: Colors.secondary,
			'&::before': { backgroundColor: Colors.secondary }
		},
		'&::before': {
			content: '""',
			position: 'absolute',
			left: '12px',
			width: '10px',
			height: '10px',
			backgroundColor: Colors.grey,
			borderRadius: '50%',
			zIndex: '1',
			'&.subStep-complete': {
				backgroundColor: `${Colors.secondary} !important`
			},
			'&.subStep-current': {
				backgroundColor: `${Colors.secondary} !important`
			}
		},
		[Breakpoints.maxWidth('md')]: {
			display: 'none'
		}
	},
	circle: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		width: '32px',
		height: '32px',
		fontSize: '16px',
		fontWeight: 'bold',
		border: `2px solid ${Colors.grey}`,
		borderRadius: '16px',
		marginRight: '10px',
		position: 'relative',
		background: Colors.lightGrey,
		zIndex: 1,
		[Breakpoints.maxWidth('md')]: {
			width: '26px',
			height: '26px',
			fontSize: '14px',
		}
	},
	text: {
		fontSize: '12px',
		fontWeight: 700,
		lineHeight: '20px',
		[Breakpoints.maxWidth('md')]: {
			display: 'none'
		}
	},
	icon: {
		width: '26px',
		height: '32px',
		display: 'none'
	},
	line: {
		position: 'absolute',
		left: '50%',
		width: '2px',
		height: '100%',
		backgroundColor: Colors.grey,
		zIndex: 0
	}
})

const ProgressList: FC<Props> = ({
	currentStep,
	currentSubStep,
	steps,
	onSetSubStep
}) => {
	const createSteps = (step, index) => {
		const isStepComplete = index + 1 < currentStep ? 'step-complete' : ''
		const isCurrentStep = index + 1 === currentStep ? 'step-current' : ''

		return (
			<div className={classes.stepContainer} key={index}>
				<div
					className={joinClasses([classes.step, isCurrentStep, isStepComplete])}
				>
					<div style={{ display: 'flex', alignItems: 'center' }}>
						<div className={joinClasses([classes.circle, 'circle'])}>
							<span>{index + 1}</span>
						</div>
						<div className={classes.text}>{step.title}</div>
					</div>
				</div>
				{/* Render sub-steps if they exist */}
				{isCurrentStep &&
					step.subSteps?.map((subStep, subIndex) => {
						const isSubStepComplete =
							index + 1 < currentStep ||
							(index + 1 === currentStep && subIndex + 1 < currentSubStep)
								? 'subStep-complete'
								: ''
						const isCurrentSubStep =
							index + 1 === currentStep && subIndex + 1 === currentSubStep
								? 'subStep-current'
								: ''

						return (
							<div
								key={subIndex}
								className={joinClasses([
									classes.subStep,
									isSubStepComplete,
									isCurrentSubStep
								])}
								onClick={() => isSubStepComplete && onSetSubStep(subIndex + 1)}
							>
								{subStep}
							</div>
						)
					})}
			</div>
		)
	}

	return (
		<div className={classes.root}>
			{steps.map((step, index) => createSteps(step, index))}
		</div>
	)
}

export default ProgressList
