import React, { FC, useContext, useEffect, useState } from 'react'
import RequestConfirmation from '@components/ui/requestConfirmation'
import SectionTitle from '@components/ui/sectionTitle'
import Text from '@components/ui/text'
import SelectionPDF from '@components/requestForms/selection.pdf.form'
import RequestCore, { RequestFormType } from '@templates/requests/create.core'
import { PageTitle } from '@components/configs/PageTitle'
import { joinClasses } from '@utils/styles'
import { useAppDispatch } from '@services/store'
import {
	populateRequestModelState,
	populateRequestConfigsState,
	populateRequestLocationState
} from '@services/store/request'
import {
	AppContextProps,
	AppStateContext
} from '@components/layouts/DynamicLayout'
import {
	IRequestConfigsState,
	IRequestLocationState
} from '@services/store/request/type'
import * as pageUtils from '@templates/requests/__create.utils'
import {
	convertStringToNumber,
	formatHtmlForStrapiText,
	formatStrapiText,
	saveFileToStore,
	saveSeparetedFileToStore
} from '@utils/methods'
import config from '@utils/config'
import { navigate } from 'gatsby'
import { chevronLightBlueIcon, externalLink } from '@images/icons'
import { Icon } from '@components/ui/Icon'
import {
	fileInputList,
	LanguageEnum,
	REQUEST_CONFIGS_KEYS,
	REQUEST_TN_DETAILS_STEPS_NAME,
	requestCategories
} from '@services/constants'
import { Collapse } from 'react-collapse'
import {
	AddressInputName,
	RequestInputFiles,
	RequestInputs,
	StepType,
	TNRequestNames
} from '@utils/request'
import { IWaterCounterAddressDto, RequestMetadata } from '@services/types'
import { REQUEST_CONFIG_KEY_RELATION } from '@services/store/request/constant'
import { Scrapper } from '@services/models/scrapper.model'
import { URL_PARAMS } from '@services/constants'
import Loader from '@components/ui/loader'
import RequestFormPDFSummary from '@components/ui/RequestFormPDFSummary/requestPDFSummary'
import Modal from '@components/ui/modal'
import { resScrapperData } from '@services/types/scrapper'
import { useAppSelector } from '@services/store'
import RequiredIcon from '@components/ui/requiredIcon'
import EligibilityRequirement, {
	ACCORDION_DESCRIPTION
} from '@components/ui/eligibilityRequirement'
import Notification from '@components/ui/notification'
import { pageUrlDic } from '@templates/requests/__create.utils'
import RequestFailedModal from '@components/ui/requestFailedModal'
import AddressValidationModal from '@components/ui/addressValidationModal'
import {
	getAddressByCoordinates,
	getDefaultCadastralNumber
} from '@components/ui/addressForm/__index.utils'
import { AddressCoordinates, Contact, User } from '@services/models'
import { useAccount, useMsal } from '@azure/msal-react'
import { Coordinate } from '@components/ui/map/mapForm.interfaces'
import CompactFooter from '@components/ui/footer/compactFooter'
import { Request } from 'typings/shared'
import Invoice from '@components/ui/invoice'
import DisplayInforModal from '@components/ui/modals/displayInfoModal'

import moment from 'moment'
import { TAB } from '@pages/auth/profile/__index.utils'
import ProgressList from '@components/ui/progressBar/progressList'
import ProfilePageRedirectModal from '@components/ui/modals/profilePageRedirectModal'
import underConstruction from '@images/Screen-Recording-construction.gif'

export type thirdStepSubStepsUrbanism = {
	map: boolean
	details_1: boolean
	details_2?: boolean
	details_3?: boolean
	details_4?: boolean
	details_5?: boolean
	details_6?: boolean
	requiredDocument: boolean
	steps: string[]
	position: REQUEST_TN_DETAILS_STEPS_NAME
}

export type filledInformation = {
	inputs: RequestInputs[]
	inputFiles: RequestInputFiles[]
	extraFiles: Set<File>
}

const CreateRequestPdfUR: FC<pageUtils.Props> = ({ pageContext: { id } }) => {
	const {
		title,
		pageAssets,
		request,

		listSteps,
		currentStep,
		loading,
		requestCreated,
		browser,
		inputs,
		closeLink,
		isAuthUserInLaval,
		address,
		cadastralAddress,
		apartment,
		errors,
		coordinates,
		filesInputs,
		city,
		postalCode,
		useDefaultUserLocation,
		requestHasError,
		createdRequestWithInvoice,
		setErrors,
		onAddErrorFromAddress,
		setApartment,
		setAddress,
		setCurrentStep,
		onSubmit,
		setInputs,
		setDoSentPost,
		setCoordinates,
		setFilesInputs,
		setCity,
		setPostalCode,
		setUseDefaultUserLocation,
		setCadastralAddress,
		setRequestHasError,
		onResetRequestFailed,
		isDeclarationRequest,
		isRequestHasPayementOnDepot
	}: RequestFormType = RequestCore(id)
	const { setAuthUser, authUser, language } =
		useContext<AppContextProps>(AppStateContext)
	const customLocationState = useAppSelector((state) => state.request.location)
	const answers = useAppSelector((state) => state.request.configs)

	const dispatch = useAppDispatch()

	const [isAddressRequired, setIsAddressRequired] = useState<boolean>(false)
	const [extraFiles, setExtraFiles] = useState<Set<File>>(new Set<File>())
	const [hasFileError, setHasFileError] = useState<boolean>(false)
	const [hasFileRequiredError, setHasFileRequiredError] =
		useState<boolean>(false)
	const [subSteps, setSubSteps] = useState<thirdStepSubStepsUrbanism>()

	const initialStepsList = [
		{
			title: formatStrapiText(pageAssets?.page_requestInfo_step1),
			subSteps: []
		},
		{
			title: formatStrapiText(pageAssets?.page_requestInfo_step2),
			subSteps: []
		},
		{
			title: formatStrapiText(pageAssets?.page_requestInfo_step3),
			subSteps: []
		},
		{
			title: formatStrapiText(pageAssets?.page_requestInfo_step4),
			subSteps: []
		},
		{
			title: formatStrapiText(pageAssets?.page_requestInfo_step5),
			subSteps: []
		}
	]
	// stepsList is the list of steps with the subSteps, it's used to display the progressList
	const [stepsList, setStepsList] =
		useState<{ title: string; subSteps: string[] }[]>(initialStepsList)

	const [steps, setSteps] = useState<StepType>({
		start: 1,
		applicant: 2,
		form: 3,
		summary: 4,
		confirmation: 5
	})

	const [currentSubStep, setCurrentSubStep] = useState(1)

	type RequestInputFilesArray = Array<RequestInputFiles<any>>
	const [inputFiles, setInputFiles] = useState<RequestInputFilesArray>([])
	const [pageId, setPageId] = useState<string>('')
	const [eligibilityRequirement, setEligibilityRequirement] = useState<
		string | resScrapperData['json']
	>()
	const [conditionsAccepted, setConditionsAccepted] = useState<boolean>(false)
	const [customError, setCustomError] = useState<boolean>(false)
	const [mapLabel, setMapLabel] = useState<string>(
		pageAssets?.request_form_step_map
	)
	const [isOpenModal, setIsOpenModal] = useState<boolean>(false)
	const [isEligibilityAvailable, setIsEligibilityAvailable] =
		useState<boolean>(true)
	const [disableNextButton, setDisableNextButton] = useState<boolean>(true)
	const [isValidating, setIsValidating] = useState<boolean>(false)
	const [openValidationModal, setOpenValidationModal] = useState<boolean>(false)
	const [shouldValidateAddress, setShouldValidateAddress] =
		useState<boolean>(false)
	const [shouldMapDisplay, setShouldMapDisplay] = useState<boolean>(false)
	const [shouldCheckAddress, setShouldCheckAddress] = useState<boolean>(false)
	const [isUpdatingAddress, setIsUpdatingAddress] = useState<boolean>(false)
	const [mapCoordinates, setMapCoordinates] = useState<Coordinate>()
	const [mapAddress, setMapAddress] = useState<string>('')
	const [mapPostalCode, setMapPostalCode] = useState<string>()
	const [mapCity, setMapCity] = useState<string>('')
	const [proposedAddress, setProposedAddress] = useState<any>()
	const { accounts } = useMsal() ?? []
	const account = useAccount(accounts[0] || {})
	const contact = authUser?.profile || new Contact()
	const [stepDescription, setStepDescription] = useState(listSteps)
	const [requiredFieldAttributes, setRequiredFieldAttributes] = useState<
		string[][]
	>([])
	const [requiredFileAttributes, setRequiredFileAttributes] = useState<
		string[]
	>([])
	const [isRequestAddressOutsideLaval, setIsRequestAddressOutsideLaval] =
		useState<boolean>(false)
	const [
		isAddressOutsideLavalMessageShow,
		setIsAddressOutsideLavalMessageShow
	] = useState<boolean>(false)

	const scrappy = async () => {
		const pageId = location.pathname.split('/').pop()!

		if (pageId == pageUtils.FORMS_ID.ANNUAL_PERMIT_AND_TAG_FOR_ANIMALS) {
			const accRequirement = getAdmissibleConditionFromStrapiData(
				pageAssets?.form_annualPermitAndTagForAnimals_admissible_condition
			)
			setEligibilityRequirement(accRequirement)
			return
		}

		if (pageId == pageUtils.FORMS_ID.MUNICIPAL_EVALUATION_FORM) {
			const accRequirement = getAdmissibleConditionFromStrapiData(
				pageAssets?.form_municipalEvaluation_admissible_condition
			)
			setEligibilityRequirement(accRequirement)
			return
		}

		const scrap = new Scrapper({
			url: pageUtils.pageUrlDic[pageId]?.url,
			selector: '.mondossier-show'
		})
		await scrap.launchScrapping().then((data) => {
			if (!!data.length) {
				switch (pageId) {
					case pageUtils.FORMS_ID.SWIMMING_POOL_SPA_PERMIT:
						const swimmingPoolRequirements = formatSwimmingPoolEligibility(data)
						setEligibilityRequirement(swimmingPoolRequirements)
						break
					default:
						const position = pageUtils.pageUrlDic[pageId].position
						if (!Array.isArray(position)) {
							let htmlText: string | resScrapperData['json'] =
								data[position].json ||
								formartEligibilityRequirementBody(data[position].html, pageId)
							setEligibilityRequirement(
								htmlText || pageAssets?.scrapper_error_occurred
							)
						} else {
							const accordionData: resScrapperData['json'] = []
							const positions = position as number[]
							positions
								.map((position) => data[position])
								.forEach((result) => {
									if (!result.json) {
										const htmlString = result.html
										const { title, summary } = extractDataFromText(htmlString)
										accordionData.push({
											accordion: {
												Title: title,
												Summary: summary
											}
										})
									} else {
										accordionData.push(...result.json)
									}
								})

							const formatedData = accordionData.map((acc, index) => {
								return {
									accordion: {
										Title: acc.accordion.Title,
										Summary: formartEligibilityRequirementBody(
											acc.accordion.Summary,
											pageId,
											index
										)
									}
								}
							})

							setEligibilityRequirement(
								formatedData || pageAssets?.scrapper_error_occurred
							)
						}
						break
				}
			} else {
				setEligibilityRequirement(pageAssets?.scrapper_error_occurred)
			}
		})
	}

	const formartEligibilityRequirementBody = (
		data: string,
		pageId: string,
		index?: number
	) => {
		switch (pageId) {
			case pageUtils.FORMS_ID.TREE_PLANTING_FORM:
				return data.replace(/<h2.*?<\/h2>/g, '')
			default:
				return data
		}
	}

	const extractDataFromText = (text: string): pageUtils.EligibilityContent => {
		const parser = new DOMParser()
		const doc = parser.parseFromString(text, 'text/html')
		const h2Title = doc.querySelector('h2')?.textContent
		const h3Title = doc.querySelector('h3')?.textContent

		const title =
			h2Title ||
			h3Title ||
			pageAssets?.page_requestInfo_eligibility_requirements

		const summary = h2Title
			? text?.replace(/<h2.*?<\/h2>/, '')
			: h3Title
			? text?.replace(/<h3.*?<\/h3>/, '')
			: text
		return {
			title,
			summary,
			doc
		}
	}

	const getAdmissibleConditionFromStrapiData = (
		strapiData: string
	): resScrapperData['json'] => {
		const accordionData: resScrapperData['json'] = []
		const accordionElements = strapiData?.split('<br>')
		accordionElements?.map((element) => {
			const { summary, title } = extractDataFromText(
				formatHtmlForStrapiText(element)
			)
			accordionData.push({
				accordion: {
					Title: title,
					Summary: formatHtmlForStrapiText(summary)
				}
			})
		})
		return accordionData
	}

	// special case for swimming pool
	const formatSwimmingPoolEligibility = (data: resScrapperData[]) => {
		let result: resScrapperData['json'] = []
		const requirementOrder = [
			{
				title:
					pageAssets?.request_swimmingPoolSpaPermit_admissible_condition_title1,
				groupOrder: [0]
			},
			{
				title:
					pageAssets?.request_swimmingPoolSpaPermit_admissible_condition_title2,
				groupOrder: [1, 2]
			},
			{
				title:
					pageAssets?.request_swimmingPoolSpaPermit_admissible_condition_title3,
				groupOrder: [3]
			}
		]

		requirementOrder.map((element) => {
			const title = element.title
			let summary = ''
			element.groupOrder.map((item) => {
				const dataItem = data[item]

				if (!dataItem?.json) {
					summary += dataItem?.html + '<br />'
				} else {
					dataItem?.json?.map((acc) => {
						summary +=
							`<h4 
						 style="color:#0054a6;"
						>${acc.accordion.Title}</h4>` + acc.accordion.Summary
					})
				}
			})

			result.push({
				accordion: {
					Title: title,
					Summary: summary
				}
			})
		})

		return result
	}

	useEffect(() => {
		scrappy()
		setPageId(location.pathname.split('/').pop()!)
	}, [])

	const cleanStorage = () => {
		for (let name of fileInputList) {
			localStorage.removeItem(name)
		}
	}

	useEffect(() => {
		cleanStorage()

		return () => {
			cleanStorage()
		}
	}, [])

	useEffect(() => {
		window?.scrollTo({ top: 0, behavior: 'smooth' })
	}, [subSteps, steps])

	useEffect(() => {
		if (coordinates && errors.length > 0) {
			setErrors(errors.filter((error: string) => error !== AddressInputName))
		}
	}, [coordinates])

	const processingData = () => {
		let config: IRequestConfigsState = {} as IRequestConfigsState

		inputs.forEach((input) => {
			if (input.value !== '') {
				let configsInputValue: number | string | boolean = input.value
				switch (REQUEST_CONFIG_KEY_RELATION[input.name]) {
					case 'number':
						configsInputValue = Number(configsInputValue)
						break
					case 'boolean':
						configsInputValue =
							String(configsInputValue)?.toLowerCase() === 'true'
						break
					default:
						break
				}
				config[input.name] = configsInputValue
			} else if (input.name) {
				config['buildingOwnerInfo'] = {
					first_name: config['ownerFirstName'],
					last_name: config['ownerLastName'],
					telephone: config['ownerPhoneNumber'],
					email: config['ownerEmail']
				}
				config['representativeInfo'] = {
					organization_name: config['companyRepresentativeName'],
					telephone: config['companyRepresentativePhoneNumber'],
					email: config['companyRepresentativeEmail']
				}
				config['contactDetailsInfo'] = {
					address: config['companyAddress'],
					city: config['companyCity'],
					postal_code: config['companyPostalCode']
				}
				config['representativeInfo2'] = {
					organization_name: config['entrepreneurRepresentativeName'],
					telephone: config['entrepreneurRepresentativePhoneNumber'],
					email: config['entrepreneurRepresentativeEmail']
				}
				config['contactDetailsInfo2'] = {
					address: config['entrepreneurRepresentativeAddress'],
					city: config['entrepreneurRepresentativeCity'],
					postal_code: config['entrepreneurRepresentativePostalCode']
				}
				config['poolSellerInfo'] = {
					organization_name: config['companyPoolName'],
					address: config['companyAdresse'],
					city: config['companyCity'],
					postal_code: config['companyPostalCode'],
					telephone: config['companyPhoneNumber'],
					email: config['companyEmail']
				}
			}
		})

		dispatch(populateRequestConfigsState(config))

		if (request?.name == TNRequestNames.rainwaterBarrel) {
			dispatch(populateRequestLocationState(customLocationState))
		} else {
			dispatch(
				populateRequestLocationState({
					city: city || authUser?.profile?.address.city,
					address: address || authUser?.profile?.address.address,
					apartment: apartment || authUser?.profile?.address.apartment,
					postalCode: postalCode || authUser?.profile?.address.postalCode,
					cadastralAddress,
					coordinates: {
						longitude:
							coordinates?.longitude ||
							authUser?.profile?.address.coordinates?.longitude,
						latitude:
							coordinates?.latitude ||
							authUser?.profile?.address.coordinates?.latitude
					}
				})
			)
		}

		dispatch(
			populateRequestModelState({
				files: [...extraFiles]
			})
		)

		setDoSentPost(!hasFileError && !hasFileRequiredError)
	}

	const processingDataAndValidation = (): boolean => {
		let details: string = ''
		let configs: IRequestConfigsState = {} as IRequestConfigsState
		const listErrors: string[] = []
		const metadata: RequestMetadata[] = []
		let isFirstErrorAlreadySet: boolean = false

		if (subSteps && subSteps.position === REQUEST_TN_DETAILS_STEPS_NAME.MAP) {
			if (!coordinates && !useDefaultUserLocation) {
				listErrors.push(AddressInputName)
				isFirstErrorAlreadySet = true
			}

			inputs.forEach((input: RequestInputs) => {
				if (
					input.required &&
					[
						'buildingCadastralNumber',
						'isOverYearOwner',
						'notarialRegistrationDate',
						'notarialRegistrationNumber',
						'buildingPostalCode',
						'buildingCity',
						'isLandOwner',
						'propertyIsOwner',
						'declarationExistsOnAddress',
						'subventionExistsOnAddress'
					].includes(String(input.name))
				) {
					if (input.value === '') {
						listErrors.push(input.name)

						if (input.ref && !isFirstErrorAlreadySet) {
							input.ref.current?.focus()
							isFirstErrorAlreadySet = true
						}
					} // validation of Date inputs
					else if (input.extraParams?.type == 'date') {
						var inputDate = new Date(input.value)
						var minDate = new Date(input.extraParams?.min!)
						var maxDate = new Date(input.extraParams?.max!)

						if (inputDate < minDate || inputDate > maxDate) {
							listErrors.push(input.name)
						}
					} else {
						configs[input.name] = input.value
					}
				}
			})

			let locationState = {
				useDefaultUserLocation,
				city,
				address,
				apartment,
				postalCode,
				cadastralAddress,
				coordinates: {
					longitude: coordinates?.longitude,
					latitude: coordinates?.latitude
				}
			}

			dispatch(populateRequestLocationState(locationState))
		}

		if (
			subSteps &&
			(subSteps.position === REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_1 ||
				subSteps.position === REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_2 ||
				subSteps.position === REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_3 ||
				subSteps.position === REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_4 ||
				subSteps.position === REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_5 ||
				subSteps.position === REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_6 ||
				subSteps.position === REQUEST_TN_DETAILS_STEPS_NAME.DOCUMENTS)
		) {
			inputs.forEach((input: RequestInputs) => {
				// differentiate DETAILS inputs from DESCRIPTION inputs substep
				if (
					(subSteps.position == REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_1 &&
						input.subStep == undefined) ||
					subSteps.position == input.subStep
				) {
					// special case for plantedTreeList (value is a serialized array)
					switch (input.name) {
						default:
							// the usual validation cases
							if (input.required && input.value === '') {
								listErrors.push(input.name)

								if (
									input.ref &&
									// check to prevent TypeError: _.focus is not a function
									input.ref?.current instanceof HTMLElement &&
									!isFirstErrorAlreadySet
								) {
									input.ref?.current?.focus()
									isFirstErrorAlreadySet = true
								}
							}

							if (
								input.required &&
								REQUEST_CONFIG_KEY_RELATION[input.name] === 'number' &&
								Number.isNaN(Number(input.value))
							) {
								listErrors.push(input.name)
							}

							if (!input.required && input.value !== '') {
								// details += `${input.label} ${input.value}\r`

								metadata.push({
									label: input.labelKey,
									value: `${input.valueKey ? input.valueKey : input.value}`
								})
							}

							// validation of Date inputs
							if (input.value !== '' && input.extraParams?.type == 'date') {
								var inputDate = new Date(input.value)
								var minDate = new Date(input.extraParams?.min!)
								var maxDate = new Date(input.extraParams?.max!)

								if (inputDate < minDate || inputDate > maxDate) {
									listErrors.push(input.name)
								}
							}

							if (
								input.value !== '' &&
								!config.request.create.fieldsToExcludeFromConfig.includes(
									input.name
								)
							) {
								let configsInputValue: number | string | boolean = input.value

								switch (REQUEST_CONFIG_KEY_RELATION[input.name]) {
									case 'number':
										configsInputValue = convertStringToNumber(configsInputValue)
										break
									case 'boolean':
										configsInputValue =
											String(configsInputValue)?.toLowerCase() === 'true'
										break
									default:
										break
								}

								configs[input.name] = configsInputValue

								if (input.labelKey !== '') {
									metadata.push({
										label: input.labelKey,
										value: `${input.valueKey ? input.valueKey : input.value}`,
										required: true
									})
								}
							}

							if (
								!input.required &&
								input.value == '' &&
								answers.hasOwnProperty(input.name)
							) {
								configs[input.name] = undefined
							}
							break
					}
				} else if (
					(subSteps.position == REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_2 &&
						input.subStep == undefined) ||
					subSteps.position == input.subStep
				) {
					switch (input.name) {
						case 'hydraulicTestList':
							let hydraulicTestList: string[] = JSON.parse(input.value)
							configs[input.name] = hydraulicTestList
							break
						default:
							break
					}
				}
			})
		}

		dispatch(populateRequestConfigsState(configs))

		// Verify if a document exists within the sub steps apart from the document step
		const subStepHasDocument = inputFiles.find(
			(input: RequestInputFiles) =>
				input.subStep === REQUEST_TN_DETAILS_STEPS_NAME.DOCUMENTS
		)

		if (
			(subSteps &&
				subSteps.position === REQUEST_TN_DETAILS_STEPS_NAME.DOCUMENTS) ||
			!!subStepHasDocument
		) {
			const cleanedFiles: RequestInputFiles[] = []

			inputFiles.forEach((input: RequestInputFiles) => {
				// Les documents qui doivent être validés dans une sous-étape doivent être définis dans la variable shouldValidateIn.
				const fileIsEmpty = !!input.separateFiles
					? Object.keys(input.separateFiles).length === 0
					: input.files.size === 0
				const hasError = input.shouldValidateIn
					? subSteps &&
					  subSteps.position == input.shouldValidateIn &&
					  input.required
					: input.required

				if (hasError && fileIsEmpty) {
					listErrors.push(input.name)
				}
				// clean deleted files of non required files
				if (!input.required && input.files.size == 0) {
					let config = { ...configs }

					config[input.name] = {}
					dispatch(populateRequestConfigsState(config))
				}

				if (
					input.files.size > 0 ||
					(!!input.separateFiles && Object.keys(input.separateFiles).length > 0)
				) {
					if (
						input.numberFilesRequired &&
						(!!input.separateFiles
							? Object.keys(input.separateFiles).length <
							  input.numberFilesRequired
							: input.files.size < input.numberFilesRequired)
					) {
						listErrors.push(input.name)
					}

					if (!!input.separateFiles) {
						Object.keys(input.separateFiles).forEach((key) => {
							if (input.separateFiles![key].size == 0)
								listErrors.push(input.name)
						})
					}

					if (input.namingValidation) {
						input.files.forEach((currentFile) => {
							if (!currentFile?.name.includes(`${input.namingValidation}`)) {
								listErrors.push(input.name)
							}
						})
					}

					cleanedFiles.push(input)

					// processing/mapping files then dispatch configs to store
					if (!!input.separateFiles) {
						// inputs with SeparetedFiles
						saveSeparetedFileToStore(input, configs).then(() => {
							dispatch(populateRequestConfigsState(configs))
						})
					} else {
						// other inputs
						saveFileToStore(input, configs).then(() => {
							dispatch(populateRequestConfigsState(configs))
						})
					}
				}
			})

			setFilesInputs(cleanedFiles)
		}

		dispatch(
			populateRequestModelState({
				description: details,
				metadata
			})
		)

		setErrors(listErrors)

		return listErrors.length === 0
	}

	// description: fct onSetSubStep for progressList, take a subStep and set the subStep in the state
	const onSetSubStep = (subStep: number) => {
		if (subSteps) {
			setSubSteps({
				...subSteps,
				position: REQUEST_TN_DETAILS_STEPS_NAME[subSteps.steps[subStep - 1]]
			})
		}
		setCurrentSubStep(subStep)
		setErrors([])
	}

	// Passer à l'étape ou sous-étape suivante
	const goToNextStep = () => {
		const current = stepsList[currentStep - 1]

		if (subSteps && currentStep === steps.form) {
			if (!processingDataAndValidation() || customError) {
				return
			}
		}

		if (current.subSteps.length && currentSubStep < current.subSteps.length) {
			const nextSubStep = currentSubStep + 1

			// if (subSteps && currentStep === steps.form) {
			// 	if (!processingDataAndValidation() || customError) {
			// 		return
			if (subSteps) {
				setSubSteps({
					...subSteps,
					position:
						REQUEST_TN_DETAILS_STEPS_NAME[subSteps.steps[nextSubStep - 1]]
				})
			}
			setCurrentSubStep(nextSubStep)
		} else if (currentStep < stepsList.length) {
			const nextStep = currentStep + 1
			setCurrentStep(nextStep)
			setCurrentSubStep(1) // Réinitialise les sous-étapes

			// Mettre à zéro la position des sous-étapes lorsque l'on entre dans la step.form
			if (subSteps && nextStep === steps.form) {
				setSubSteps({
					...subSteps,
					position: REQUEST_TN_DETAILS_STEPS_NAME[subSteps.steps[0]] // Réinitialise à la première sous-étape
				})
			}
		}
	}

	// Revenir à l'étape ou sous-étape précédente
	const goToPreviousStep = () => {
		const current = stepsList[currentStep - 1]
		if (current.subSteps.length && currentSubStep > 1) {
			const prevSubStep = currentSubStep - 1

			if (subSteps && currentStep === steps.form) {
				setSubSteps({
					...subSteps,
					position:
						REQUEST_TN_DETAILS_STEPS_NAME[subSteps.steps[prevSubStep - 1]]
				})
				setErrors([])
			}

			setCurrentSubStep(prevSubStep)
		} else if (currentStep > 1) {
			const prevStep = currentStep - 1

			// Mettre à zéro la position des sous-étapes lorsque l'on entre dans la step.form
			if (subSteps && prevStep === steps.form) {
				setSubSteps({
					...subSteps,
					position:
						REQUEST_TN_DETAILS_STEPS_NAME[
							subSteps.steps[subSteps.steps.length - 1]
						]
				})
				setErrors([])
			}

			setCurrentStep(prevStep)
			setCurrentSubStep(
				stepsList[prevStep - 1].subSteps?.length > 0
					? stepsList[prevStep - 1].subSteps?.length
					: 1
			)
		}
	}

	useEffect(() => {
		const param = new URLSearchParams(location?.search)
		const step = param.get(URL_PARAMS.STEP)
		if (step) {
			setCurrentStep(parseInt(step))
		}
	}, [])

	useEffect(() => {
		const currentLocation = location.href
		const preventFromLeaving = (e) => {
			e.preventDefault()

			var r = confirm(pageAssets?.createRequest_page_do_you_want_to_leave)
			if (r == true) {
				history.back()
			} else {
				navigate(currentLocation)
				history.replaceState(null, '', currentLocation)
			}
		}

		window.addEventListener('popstate', preventFromLeaving)
		return () => {
			window.removeEventListener('popstate', preventFromLeaving)
		}
	}, [])

	useEffect(() => {
		if (!!eligibilityRequirement) {
			const divs = document.getElementsByClassName(
				`${ACCORDION_DESCRIPTION.value}`
			)
			Array.prototype.forEach.call(divs, (div: HTMLDivElement) => {
				const links = div.getElementsByTagName('a')

				Array.prototype.forEach.call(links, (link) => {
					const attribute = link.href.split('Pages')
					if (attribute.length == 2) {
						if (attribute[0].includes('Documents')) {
							link.href = 'https://www.laval.ca/Documents/Pages' + attribute[1]
						} else {
							link.href = 'https://www.laval.ca/Pages' + attribute[1]
						}
						link.target = '_blank'
					}
				})

				const images = div.getElementsByTagName('img')
				Array.prototype.forEach.call(images, (image) => {
					let attribute
					if (image.src.includes('PublishingImages')) {
						attribute = image.src.split('PublishingImages')
						image.src = 'https://www.laval.ca/PublishingImages' + attribute[1]
					} else {
						attribute = image.src.split('_layouts')
						image.src = 'https://www.laval.ca/_layouts' + attribute[1]
					}
				})
			})
		}
	}, [eligibilityRequirement])

	useEffect(() => {
		if (!eligibilityRequirement?.length) {
			const timer = setTimeout(() => {
				setIsEligibilityAvailable(false)
			}, 10000)

			return () => clearTimeout(timer)
		}
	}, [eligibilityRequirement])

	useEffect(() => {
		if (isBeforeStartingStepRequired() && request?.name !== undefined) {
			//  No next step function since there is no eligibility requirement page
			validateAddressBeforeNextStep(() => {}, setOpenValidationModal)
		}
	}, [])

	const getEligibilityRequirementInfo = (): string => {
		const text =
			pageAssets?.request_form_information_is_not_available_at_the_moment
		const eligibilityUrl = `<a href="${pageUrlDic[id]?.url}">${pageUrlDic[id]?.url}</a>`
		return text + eligibilityUrl
	}

	// const confirmRedirectModal = () => {
	// 	return (
	// 		<Modal
	// 			isOpen={isOpenModal}
	// 			onSetIsOpen={setIsOpenModal}
	// 			primaryButtonText={pageAssets?.button_continue}
	// 			onPrimaryButton={() => {
	// 				setIsOpenModal(false)
	// 				navigate(`/auth/profile`, {
	// 					state: {
	// 						form: true,
	// 						url: location.pathname,
	// 						step: 2
	// 					}
	// 				})
	// 			}}
	// 			showExitBtn
	// 			alignBtn="left"
	// 		>
	// 			<div style={{ paddingRight: '25px', marginTop: '-35px' }}>
	// 				<Text content={pageAssets?.redirect_to_personal_info_modal_text} />
	// 			</div>
	// 		</Modal>
	// 	)
	// }

	const renderRequestFailedModal = () => {
		return (
			<RequestFailedModal
				requestHasError={requestHasError}
				setRequestHasError={setRequestHasError}
				onResetRequestFailed={onResetRequestFailed}
				closeLink={closeLink}
				setDoSentPost={setDoSentPost}
			/>
		)
	}

	const isBeforeStartingStepRequired = (): boolean => {
		const requestTypes = [
			TNRequestNames.oilHeatingDeclaration,
			TNRequestNames.selfReliefWaterCounter,
			TNRequestNames.fireplaceDeclaration
		]
		return !requestTypes.includes(request?.name ?? '')
	}

	const showRequestConfirmationWrapper = (): boolean => {
		let requestTypes = [
			// Poteaux d'incendie
			TNRequestNames.fireHydrantsTankFilling,
			TNRequestNames.fireHydrantsTempAqueductNetwork,
			TNRequestNames.hydraulicAndPressureTests,
			// Subvention Mazout
			TNRequestNames.replacementOilHeatingSystem,
			TNRequestNames.fireplaceDeclaration
		]

		if (
			[
				REQUEST_CONFIGS_KEYS.claimNature.bodilyInjury,
				REQUEST_CONFIGS_KEYS.claimNature.roadWeatheringDamage
			].includes(answers.claimNature?.toString()!)
		) {
			requestTypes.push(TNRequestNames.claimNotice)
		}

		return requestTypes.includes(request?.name ?? '')
	}

	const setProfileRedirectTab = (tab: TAB) => {
		localStorage.setItem(config.localStorage.activeTab, tab)
	}

	const getCloseLink = (): string => {
		switch (request?.name) {
			case TNRequestNames.claimNotice:
				return config.homepage.baseURL
			case TNRequestNames.municipalEvaluation:
				setProfileRedirectTab(TAB.PROPERTY)
				return config.auth.profile.baseUrl
			case TNRequestNames.oilHeatingDeclaration:
			case TNRequestNames.selfReliefWaterCounter:
				return config.auth.profile.baseUrl
			default:
				return closeLink
		}
	}

	const validateAddressBeforeNextStep = async (
		callbacK: () => void,
		setOpenModal: (value: React.SetStateAction<boolean>) => void
	): Promise<boolean> => {
		let isValid = true
		if (
			!authUser?.profile?.address.inMunicipality ||
			!authUser?.profile?.address.hasAddressEnteredManually
		) {
			callbacK()
			return isValid
		}
		setIsValidating(true)
		const { data } = await getDefaultCadastralNumber(
			authUser?.profile?.address.address
		)

		if (data.features[0].place_name !== authUser?.profile?.address.address) {
			setProposedAddress(data.features[0])
			setOpenModal(true)
			isValid = false
		}
		setIsValidating(false)
		callbacK()
		return isValid
	}

	const updateUserAddress = async (
		updateRequestAdress?: boolean,
		isFromMap?: boolean
	) => {
		contact.address.address = isFromMap
			? mapAddress
			: proposedAddress.place_name
		contact.address.postalCode = isFromMap
			? mapPostalCode
			: proposedAddress?.properties.postal_code
		contact.address.coordinates = isFromMap
			? (mapCoordinates as AddressCoordinates)
			: contact.address.coordinates
		if (!account) {
			// we must have an account, otherwise, we're not logged in and we should not be able to access this page.
			navigate(config.homepage.baseURL)

			throw new Error(
				'No account was found to perform a contact update for the user'
			)
		}
		try {
			setIsUpdatingAddress(true)

			contact.address.prepareProperties()
			contact.applyAccountValues(account)

			await contact.saveAsync()
			const user: User = await User.buildAsync(account, contact)

			setAuthUser(user)
		} catch (error: any) {
			setIsUpdatingAddress(false)
			alert(error?.message)
			console.error('Backend Error: ', error?.message)

			throw new Error(error?.message)
		} finally {
			setIsUpdatingAddress(false)
			setOpenValidationModal(false)
			goToNextStep()
			if (updateRequestAdress) {
				updateAddressInput(isFromMap)
				isFromMap && setShouldMapDisplay(false)
			}
		}
	}

	const updateAddressInput = (isFromMap?: boolean) => {
		let locationState = {
			useDefaultUserLocation,
			city: authUser?.profile?.address.city,
			address: isFromMap ? mapAddress : proposedAddress.place_name,
			postalCode: isFromMap
				? mapPostalCode
				: proposedAddress.properties.postal_code,
			cadastralAddress: authUser?.profile?.address.cadastralAddress,
			coordinates: {
				longitude: isFromMap
					? mapCoordinates?.longitude
					: proposedAddress.geometry.coordinates[0],
				latitude: isFromMap
					? mapCoordinates?.latitude
					: proposedAddress.geometry.coordinates[1]
			},
			hasAddressEnteredManually: false
		}

		dispatch(populateRequestLocationState(locationState))
		setShouldValidateAddress(false)
	}

	const renderAddressValidation = (
		openModal: boolean,
		setOpenModal: React.Dispatch<React.SetStateAction<boolean>>
	) => {
		return (
			<AddressValidationModal
				currentAddress={{
					address: authUser?.profile?.address.address,
					city: authUser?.profile?.address.city,
					postalCode: authUser?.profile?.address.postalCode
				}}
				proposedAddress={proposedAddress}
				isModalOpen={openModal}
				onCancelBtn={() => {
					setShouldMapDisplay(false)
				}}
				onCloseModal={() => {
					setShouldMapDisplay(true)
				}}
				onPrimaryButton={() => {
					updateUserAddress(true)
				}}
				onPrimaryBtnMap={() => {
					updateUserAddress(true, true)
				}}
				onCancelBtnMap={() => {
					setShouldMapDisplay(false)
					setMapCoordinates(undefined)
					setOpenModal(false)
				}}
				shouldMapDisplay={shouldMapDisplay}
				onRetreiveCoordinates={setMapCoordinates}
				onSetPostalCode={setMapPostalCode}
				onSetCity={setMapCity}
				onAddressInputChange={setMapAddress}
				isPrimaryBtnLoading={isUpdatingAddress}
			/>
		)
	}

	const getAcceptedTermsAndConditions = (request?: Request) => {
		switch (request?.name) {
			case TNRequestNames.annualPermitAndTagForAnimals:
			case TNRequestNames.swimmingPoolSpaPermit:
				return pageAssets?.createRequest_page_accept_terms_and_conditions_for_annualPermitAndTagForAnimals
			default:
				return pageAssets?.createRequest_page_accept_terms_and_conditions
		}
	}

	const getPageRequestInfoTitle = () => {
		if (isDeclarationRequest(request?.name!)) {
			return pageAssets?.page_requestInfo_declaration_informations
		}

		return pageAssets?.page_requestInfo_request_informations
	}

	const getPageRequestInfo = () => {
		switch (request?.name) {
			case TNRequestNames.swimmingPoolSpaPermit:
				return pageAssets?.page_requestInfo_have_read_eligibility_condition_certificate
			default:
				return pageAssets?.page_requestInfo_have_read_eligibility_condition
		}
	}

	// const requestHasPayment = (): boolean => {
	// 	const requestTypes = [
	// 		TNRequestNames.annualPermitAndTagForAnimals,
	// 		TNRequestNames.parkingPermit
	// 	]
	// 	return requestTypes.includes(request?.name ?? '')
	// }

	const renderPolicyAndSubmitBtn = () => {
		return (
			<>
				<div className={pageUtils.classes.checkbox2Container}>
					<form>
						<input
							onClick={(e: any) => {
								setConditionsAccepted(e.target.checked)
							}}
							value={`${conditionsAccepted}`}
							className={pageUtils.classes.checkbox2}
							type="checkbox"
							name="terms_and_condition"
							id="terms_and_condition"
							disabled={loading}
						/>
						<label
							className={pageUtils.classes.checkbox2Label}
							htmlFor="terms_and_condition"
						>
							{getAcceptedTermsAndConditions(request)}
							<RequiredIcon />
						</label>
					</form>
				</div>

				<div className={pageUtils.classes.submitContainer}>
					<div className={pageUtils.classes.submitRoot}>
						<button
							className={joinClasses([
								pageUtils.classes.btn,
								pageUtils.classes.button,
								pageUtils.classes.buttonOutline,
								loading ? pageUtils.classes.btnDisabled : ''
							])}
							onClick={() => goToPreviousStep()}
							style={{
								marginRight: '10px',
								marginLeft: '0px'
							}}
						>
							{formatStrapiText(pageAssets?.button_previous)}
						</button>
						<button
							type="button"
							className={joinClasses([
								pageUtils.classes.submitBtn,
								pageUtils.classes.btn,
								loading ||
								(hasFileError && hasFileRequiredError) ||
								!conditionsAccepted
									? pageUtils.classes.btnDisabled
									: ''
							])}
							onClick={onSubmit(processingData, errors.length > 0)}
						>
							{!loading && (
								<Text
									content={
										// requestHasPayment()
										// 	? pageAssets?.btn_submit
										// 	:
										pageAssets?.page_requestInfo_inputSubmit
									}
								/>
							)}
							{loading && (
								<div className={`${pageUtils.classes.loader} spin`} />
							)}
						</button>
					</div>
					{loading && (
						<span className={pageUtils.classes.loadingMessage}>
							{pageAssets?.createRequest_page_wait_while_we_submit_your_request}
						</span>
					)}
				</div>
			</>
		)
	}

	useEffect(() => {
		if (isRequestHasPayementOnDepot()) {
			if (currentStep === 5) {
				let tempList = [...listSteps]
				tempList[4] = pageAssets?.page_requestInfo_step_confirmation_and_payment
				setStepDescription(tempList)
			} else setStepDescription(listSteps)
		} else {
			setStepDescription(listSteps)
		}

		setConditionsAccepted(false)
	}, [currentStep])

	return (
		<div
			className={joinClasses([
				pageUtils.classes.root,
				browser,
				language === LanguageEnum.EN ? pageUtils.classes.withCompactFooter : ''
			])}
		>
			<div className={pageUtils.classes.contentPdf}>
				<PageTitle title={title} />

				<div className={pageUtils.classes.sectionTitle}>
					<SectionTitle
						title={title}
						subTitle={request?.title}
						showBreadcrumbs={false}
						link={getCloseLink()}
						classRoot={joinClasses([
							pageUtils.classes.sectionTitleRoot,
							pageUtils.classes.sectionTitleRootLowPadding
						])}
						showCharterInfo={false}
					/>
				</div>

				<section
					className={joinClasses([
						pageUtils.classes.container,
						pageUtils.classes.noPadding,
						pageUtils.classes.noBackground
					])}
				>
					<div className={pageUtils.classes.containerUrbanism}>
						<div className={pageUtils.classes.sidebarUrbanism}>
							<ProgressList
								steps={stepsList}
								currentStep={currentStep}
								currentSubStep={currentSubStep}
								onSetSubStep={onSetSubStep}
							/>
						</div>

						<div className={pageUtils.classes.contentUrbanism}>
							{/* start */}
							{currentStep === steps.start && (
								<section
									className={joinClasses([
										pageUtils.classes.container,
										pageUtils.classes.noPadding,
										pageUtils.classes.noBackground
									])}
								>
									<div className={pageUtils.classes.sectionBlueTitle}>
										<h3>
											{formatStrapiText(pageAssets?.page_requestInfo_step1)}
										</h3>
									</div>

									<div className={pageUtils.classes.textSection}>
										{isEligibilityAvailable && (
											<>
												{eligibilityRequirement ? (
													<>
														<div className={pageUtils.classes.headerWrapper}>
															<p>
																{' '}
																<Text content={getPageRequestInfo()} />
															</p>
														</div>

														<EligibilityRequirement
															requirements={eligibilityRequirement}
															pageId={pageId}
														/>
													</>
												) : (
													<>
														<Loader text={pageAssets?.loading} />
													</>
												)}
											</>
										)}

										<Collapse isOpened={!isEligibilityAvailable}>
											<Notification
												type="info"
												hasHtml
												externalIcon
												text={getEligibilityRequirementInfo()}
											/>
										</Collapse>
									</div>

									<div className={pageUtils.classes.buttonContainer}>
										<button
											className={joinClasses([
												pageUtils.classes.btn,
												pageUtils.classes.submitBtn,
												pageUtils.classes.button,
												isValidating ? pageUtils.classes.btnDisabled : ''
											])}
											onClick={() =>
												validateAddressBeforeNextStep(
													goToNextStep,
													setOpenValidationModal
												)
											}
										>
											{!isValidating ? (
												formatStrapiText(pageAssets?.button_start)
											) : (
												<div className={`${pageUtils.classes.loader} spin`} />
											)}
										</button>
									</div>
								</section>
							)}

							{/* applicant */}
							{currentStep === steps.applicant && (
								<>
									<section
										className={joinClasses([
											pageUtils.classes.container,
											pageUtils.classes.noPadding,
											pageUtils.classes.noBackground
										])}
									>
										<div className={pageUtils.classes.sectionBlueTitle}>
											<h3>
												{formatStrapiText(
													pageAssets?.request_form_personal_informations
												)}
											</h3>
										</div>

										<div className={pageUtils.classes.textSection}>
											<p style={{ marginTop: '0px' }}>
												{pageAssets?.request_form_confirm_personal_information}{' '}
												<span
													className={pageUtils.classes.buttonLink}
													onClick={() => setIsOpenModal(true)}
												>
													{formatStrapiText(
														pageAssets?.request_form_change_personal_information
													)}
												</span>
											</p>

											<div className={pageUtils.classes.lineDivider}>
												<div className={pageUtils.classes.applicantInformation}>
													<div className={pageUtils.classes.infoTitle}>
														<strong>{pageAssets?.label_lastName}</strong>
														<br />
														{authUser?.profile?.lastName}{' '}
														{authUser?.profile?.firstName}
													</div>
													<div className={pageUtils.classes.infoTitle}>
														<strong>{pageAssets?.label_email}</strong>
														<br />
														{authUser?.profile?.email}
													</div>
													<div className={pageUtils.classes.infoTitle}>
														<strong>{pageAssets?.label_address}</strong>
														<br />
														{authUser?.profile?.address.address}
														<br />
														{authUser?.profile?.address.city}
														{authUser?.profile?.address.state
															? ` (${authUser?.profile?.address.state}) `
															: ' '}
														{authUser?.profile?.address.postalCode}
														<br />
														{authUser?.profile?.address.apartment
															? `${formatStrapiText(
																	pageAssets?.label_apartment_office
															  )} ${authUser?.profile?.address.apartment}`
															: ''}
													</div>
													<div className={pageUtils.classes.infoTitle}>
														<strong>{pageAssets?.label_telephone}</strong>
														<br />
														{authUser?.profile?.phoneNumber ||
															authUser?.profile?.phoneNumberEvening ||
															authUser?.profile?.phoneNumberOther}
													</div>
												</div>
											</div>
										</div>
										<div className={pageUtils.classes.buttonContainer}>
											{isBeforeStartingStepRequired() && (
												<button
													className={joinClasses([
														pageUtils.classes.btn,
														pageUtils.classes.button,
														pageUtils.classes.buttonOutline
													])}
													onClick={() => goToPreviousStep()}
												>
													{formatStrapiText(pageAssets?.button_previous)}
												</button>
											)}
											<button
												className={joinClasses([
													pageUtils.classes.btn,
													pageUtils.classes.button
												])}
												onClick={() => {
													if (shouldValidateAddress) {
														setShouldValidateAddress(false)
													}

													if (!authUser) {
														return
													}

													// verifier si adresse est en dehors de Laval (no coordinates)
													// Applicant step
													if (
														[
															TNRequestNames.annualPermitAndTagForAnimals,
															TNRequestNames.clothDiapers
														].includes(request?.name!)
													) {
														const coordinates: AddressCoordinates =
															new AddressCoordinates()
														coordinates.update(
															authUser?.profile?.address.coordinates!
														)

														if (!coordinates.validate()) {
															setIsAddressOutsideLavalMessageShow(true)
															return
														}
													}

													goToNextStep()
												}}
												style={{ marginLeft: '5px' }}
											>
												{formatStrapiText(pageAssets?.button_save_and_continue)}
											</button>
										</div>
										<AddressValidationModal
											currentAddress={{
												address: authUser?.profile?.address.address,
												city: authUser?.profile?.address.city,
												postalCode: authUser?.profile?.address.postalCode
											}}
											proposedAddress={proposedAddress}
											isModalOpen={openValidationModal}
											onCancelBtn={() => {}}
											onCloseModal={() => {
												goToNextStep()
												setOpenValidationModal(false)
												setShouldValidateAddress(false)
											}}
											onPrimaryButton={() => {
												updateUserAddress()
											}}
											isPrimaryBtnLoading={isUpdatingAddress}
										/>

										<Modal
											isOpen={isAddressOutsideLavalMessageShow}
											type={'warning'}
											// openUsingSetTimeout
											// cancelButtonText={pageAssets?.request_form_understood}
											onSetIsOpen={setIsAddressOutsideLavalMessageShow}
											cancelButtonText={pageAssets?.label_return_to_form}
											primaryButtonText={pageAssets?.label_quit}
											onPrimaryButton={() => {
												if (closeLink) {
													navigate(closeLink)
												}
											}}
											alignBtn={'center'}
										>
											<div className={pageUtils.classes.modalContent}>
												{
													pageAssets?.request_form_isAddressOutsideLavalMessageShow_forApplicant_notification_label
												}{' '}
												<span
													className={pageUtils.classes.buttonLink}
													onClick={() => {
														localStorage.setItem(
															config.localStorage.activeTab,
															'0'
														)
														navigate(`/auth/profile`, {
															state: {
																form: true,
																url: location.pathname,
																step: 0
															}
														})
													}}
												>
													{formatStrapiText(
														pageAssets?.request_form_change_personal_information
													)}
												</span>
											</div>
										</Modal>
									</section>
									<ProfilePageRedirectModal
										isOpenModal={isOpenModal}
										setIsOpenModal={setIsOpenModal}
									/>
								</>
							)}

							{/* form */}
							{currentStep === steps.form && (
								<form
									className={joinClasses([
										pageUtils.classes.container,
										pageUtils.classes.pdfFormcontainer,
										pageUtils.classes.noPadding
									])}
									onSubmit={
										// Prevents the form from submitting if the ENTER key is pressed
										(e) => {
											e.preventDefault()
										}
									}
								>
									<div
										className={joinClasses([
											pageUtils.classes.sectionBlueTitle,
											pageUtils.classes.noMarginBottom
										])}
									>
										<h3>{formatStrapiText(getPageRequestInfoTitle())}</h3>
									</div>

									<Collapse
										isOpened={
											subSteps !== undefined &&
											REQUEST_TN_DETAILS_STEPS_NAME.MAP !== subSteps.position
										}
									>
										<SelectionPDF
											name={`${request?.name}`}
											files={extraFiles}
											hasFileError={hasFileError}
											hasFileRequiredError={hasFileRequiredError}
											inputs={inputs}
											errors={errors}
											inputFiles={inputFiles}
											onSetInputFiles={setInputFiles}
											onFixError={setErrors}
											onSetFiles={setExtraFiles}
											onSetHasFileError={setHasFileError}
											onSetHasFileRequiredError={setHasFileRequiredError}
											onSetInputs={setInputs}
											subSteps={subSteps}
											onSetSubSteps={setSubSteps}
											filesInputs={filesInputs}
											onCustomError={setCustomError}
											steps={steps}
											currentStep={currentStep}
											setStepsList={setStepsList}
											setSteps={setSteps}
											setDisableNextButton={setDisableNextButton}
											setRequiredFieldAttributes={setRequiredFieldAttributes}
											setRequiredFileAttributes={setRequiredFileAttributes}
										/>
									</Collapse>

									<div className={pageUtils.classes.buttonContainer}>
										<button
											className={joinClasses([
												pageUtils.classes.btn,
												pageUtils.classes.button,
												pageUtils.classes.buttonOutline
											])}
											onClick={() => {
												goToPreviousStep()
												!disableNextButton && setDisableNextButton(true)
											}}
											type="button"
										>
											{formatStrapiText(pageAssets?.button_previous)}
										</button>
										<button
											className={joinClasses([
												pageUtils.classes.btn,
												pageUtils.classes.button,
												errors.length > 0 ||
												customError ||
												!disableNextButton ||
												isValidating
													? joinClasses([
															pageUtils.classes.centerItem,
															pageUtils.classes.btnDisabled
													  ])
													: ''
											])}
											onClick={() => {
												goToNextStep()
											}}
											style={{ marginLeft: '5px' }}
											type="button"
											disabled={!disableNextButton}
										>
											{!isValidating ? (
												formatStrapiText(pageAssets?.button_save_and_continue)
											) : (
												<div className={`${pageUtils.classes.loader} spin`} />
											)}
										</button>
									</div>
								</form>
							)}
							{console.log('currentStep', currentStep)}

							{currentStep === steps.associated && (
								<section
									className={joinClasses([
										pageUtils.classes.container,
										pageUtils.classes.noPadding,
										pageUtils.classes.noBackground
									])}
								>
									<div className={pageUtils.classes.sectionBlueTitle}>
										<h3>
											{formatStrapiText(
												pageAssets?.page_requestInfo_step_complementary_requests
											)}
										</h3>
									</div>

									<div className={pageUtils.classes.textSection}>
										<img
											src={underConstruction}
											alt="soon"
											style={{
												width: '80%',
												margin: '-40px auto',
												display: 'flex'
											}}
										/>
									</div>

									<div className={pageUtils.classes.buttonContainer}>
										<button
											className={joinClasses([
												pageUtils.classes.btn,
												pageUtils.classes.button,
												pageUtils.classes.buttonOutline
											])}
											onClick={() => goToPreviousStep()}
										>
											{formatStrapiText(pageAssets?.button_previous)}
										</button>

										<button
											className={joinClasses([
												pageUtils.classes.btn,
												pageUtils.classes.button
											])}
											onClick={() => {
												goToNextStep()
											}}
											style={{ marginLeft: '5px' }}
										>
											{formatStrapiText(pageAssets?.button_save_and_continue)}
										</button>
									</div>
								</section>
							)}

							{currentStep === steps.summary && (
								<section
									className={joinClasses([
										pageUtils.classes.container,
										pageUtils.classes.noPadding,
										pageUtils.classes.noBackground
									])}
								>
									<div>
										<div>
											<RequestFormPDFSummary
												name={`${request?.name}`}
												files={extraFiles}
												mapLabel={mapLabel}
												hasFileError={hasFileError}
												hasFileRequiredError={hasFileRequiredError}
												inputs={inputs}
												errors={errors}
												inputFiles={inputFiles}
												setCurrentStep={setCurrentStep}
												onSetInputFiles={setInputFiles}
												onFixError={setErrors}
												onSetFiles={setExtraFiles}
												onSetHasFileError={setHasFileError}
												onSetHasFileRequiredError={setHasFileRequiredError}
												onSetInputs={setInputs}
												subSteps={subSteps}
												steps={steps}
												onSetSubSteps={setSubSteps}
												setSteps={setSteps}
												filesInputs={filesInputs}
												currentStep={currentStep}
												address={customLocationState.address}
												cadastralAddress={customLocationState.cadastralAddress}
												apartment={customLocationState.apartment}
												city={customLocationState.city}
												postalCode={customLocationState.postalCode}
												isSummaryStep
												policy={getAcceptedTermsAndConditions(request)}
											/>
											{/* {!requestHasPayment() && renderPolicyAndSubmitBtn()} */}
											{renderPolicyAndSubmitBtn()}
											{loading && isRequestHasPayementOnDepot() && (
												<div className={pageUtils.classes.loadingOverlay}>
													<div className={`${pageUtils.classes.loader} spin`} />
													<div>
														<strong>
															{
																pageAssets?.createRequest_page_please_do_not_refresh_the_page
															}
														</strong>
													</div>
													<div>
														{pageAssets?.createRequest_page_sending_in_progress}
													</div>
												</div>
											)}
										</div>
									</div>
									{renderRequestFailedModal()}
								</section>
							)}

							{currentStep === steps.confirmation && (
								<section
									className={joinClasses([
										pageUtils.classes.container,
										pageUtils.classes.noPadding,
										pageUtils.classes.noBackground
									])}
								>
									<RequestConfirmation
										requestCreated={requestCreated}
										request={request}
										closeLink={closeLink}
										// TODO: once this condition is accepted for all other requests either remove this prop or set it to true
										useClassWrapper={showRequestConfirmationWrapper()}
										hasPayment={isRequestHasPayementOnDepot()}
										createdRequestWithInvoice={createdRequestWithInvoice}
									>
										<RequestFormPDFSummary
											name={`${request?.name}`}
											files={extraFiles}
											hasFileError={hasFileError}
											hasFileRequiredError={hasFileRequiredError}
											inputs={inputs}
											errors={errors}
											inputFiles={inputFiles}
											filesInputs={filesInputs}
											setCurrentStep={setCurrentStep}
											onSetInputFiles={setInputFiles}
											onFixError={setErrors}
											onSetFiles={setExtraFiles}
											onSetHasFileError={setHasFileError}
											onSetHasFileRequiredError={setHasFileRequiredError}
											onSetInputs={setInputs}
											subSteps={subSteps}
											steps={steps}
											onSetSubSteps={setSubSteps}
											currentStep={currentStep}
											isSummaryStep
											address={address}
											mapLabel={mapLabel}
											apartment={apartment}
											city={city}
											postalCode={postalCode}
											request={request}
											requestCreated={requestCreated}
											toPrint
											policy={getAcceptedTermsAndConditions(request)}
										/>
									</RequestConfirmation>
								</section>
							)}
						</div>
					</div>
				</section>
			</div>
			{language === LanguageEnum.EN && <CompactFooter />}
		</div>
	)
}

export default CreateRequestPdfUR
